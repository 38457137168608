<!-- eslint-disable no-console -->
<script>
import appConfig from "@/app.config";
import api from "../../../common/axios";

/**
 * Login component
 */
export default {
  page: {
    title: "Reset Password",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  data() {
    return {
      token: null,
      model: {
        new_password: null,
        password_confirmation: null,
      },
      config: {
        api: "reset_password",
      },
      responses: {
        message: null,
        variant: null,
      },
      password: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  methods: {
    tryToLogIn() {},
    resetPassword() {
      let _ = this;
      const config = {
        headers: {
          Authorization: "Bearer " + _.token,
        },
      };
      api
        .put(_.config.api, _.model, config)
        .then((res) => {
          _.responses.message = res.data.message;
          _.responses.variant = "success";
          setTimeout(() => {
            _.$router.push("/login");
          }, 4000);
        })
        .catch((e) => {
          let vm = this;
          vm.responses.message = e.response.data.message;
          vm.responses.variant = "danger";
        });
    },
  },
  mounted() {
    this.token = this.$route.query.token;
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="text-primary text-center p-4">
              <h1>Reset Password</h1>
            </div>
            <div class="card-body px-4 pt-0">
              <div class="p-3">
                <b-alert
                  show
                  :variant="responses.variant"
                  v-if="responses.message"
                >
                  {{ responses.message }}
                </b-alert>

                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(resetPassword)"
                    class="form-horizontal mt-4"
                  >
                    <ValidationProvider
                      name="New Password"
                      rules="required"
                      vid="confirmation"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Password Baru"
                        label-for="input-2"
                        class="mb-3"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="model.new_password"
                          type="password"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Confirmation Password"
                      rules="required|confirmed:confirmation"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Konfirmasi Password"
                        label-for="input-2"
                        class="mb-3"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="model.password_confirmation"
                          type="password"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <div class="form-group row">
                      <div class="col-sm-12 ">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Simpan</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
